export const Colors = {
  primary: "#0AAD0A",
  secondary: "#eee",
  danger: "#f00",
  success: "#0f0",
  warning: "#ff0",
  info: "#00f",
  light: "#eee",
  dark: "#222",
  white: "#fff",
  black: "#000",
  transparent: "transparent",
  gray: "#ccc",
  grayDark: "#666",
  grayLight: "#eee",
};
